<template>
<form @submit.prevent="searchFilter">
  <input
  v-model="searchText"
  class="form-control form-control-sm exampleInputText"
  :placeholder="placeholder"
  />
</form>
</template>

<script>
export default {
name: "search",
props: ["placeholder", "search", "isOrder", "isSubs", "isVoucher"],
data() {
return {
searchText: this.search,
};
},

methods: {
  searchFilter() {
      var searchData
      if(this.isOrder){
        searchData = document.getElementsByClassName("exampleInputText")[1].value;
        this.$emit("searchFilter", searchData);
        return;
      }
      else if(this.isSubs){
        searchData = document.getElementsByClassName("exampleInputText")[2].value;
        this.$emit("searchFilter", searchData);
        return;
      }
      else if(this.isVoucher){
        searchData = document.getElementsByClassName("exampleInputText")[3].value;
        this.$emit("searchFilter", searchData);
        return;
      }
      else{
        searchData = document.getElementsByClassName("exampleInputText")[0].value;
        this.$emit("searchFilter", searchData);
        return;
      }
  },
},
};
</script>

<style>
.search-css{
  color: #757981;
  font-size: small;
  padding: 5px;
}
.modal-product-search .search-link {
  background: #3378ff;
  color: #fff;
  padding: 1px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
